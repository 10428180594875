import React from 'react';
import { Link, graphql } from 'gatsby';
import { shape } from 'prop-types';
import Masonry from 'react-masonry-component';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import Logo from '../components/logo';
import Stroke from '../components/stroke';

import Layout from '../components/layout';

const ReactRotatingText = require('react-rotating-text');

const services = [
  {
    name: 'Accessibility',
    icon: 'fas fa-universal-access',
    content: 'Give your users equal access to information and functionality on the web.',
  },
  {
    name: 'Business Analysis',
    icon: 'fas fa-business-time',
    content: 'Identify the needs of your organization based on data and patterns.',
  },
  {
    name: 'Information Architecture',
    icon: 'fas fa-sitemap',
    content: 'Structure your application content in an effective and sustainable way.',
  },
  {
    name: 'Project Management',
    icon: 'fas fa-project-diagram',
    content: 'Manage and coordinate a web project from its inception to the delivery.',
  },
  {
    name: 'Responsive Web Design',
    icon: 'fas fa-mobile-alt',
    content: 'Deliver an online solution that will adjust to different-sized screens and devices.',
  },
  {
    name: 'Search Engine Optimization',
    icon: 'fab fa-searchengin',
    content: 'Make your business be easily found (and ranked better on search engines).',
  },
  {
    name: 'Software Engineering',
    icon: 'fa fa-cogs',
    content: 'Build a future-proof solution with focus on design, development and maintenance.',
  },
  {
    name: 'Usability',
    icon: 'fa fa-users',
    content: 'Assess how easy and efficient your application interfaces are to use.',
  },
];

const IndexPage = ({ data }) => {
  const mostRecentWork = data.IndexQuery.edges[0].node;
  const contactImageBg = data.contactImage.childImageSharp.fluid;
  const { excerpt, slug, title } = mostRecentWork;
  return (
    <Layout>
      <BackgroundImage
        Tag="section"
        className="home-most-recent bg-gray relative"
        fluid={mostRecentWork.coverImage.fluid}
      >
        <div className="white tc tl-l absolute-ns w-100 w-50-l bw1 right-0 top-0 bottom-0 flex items-center-l">
          <div className="w-100 pv5 pv0-ns">
            <div className="pt4 pt6-m pt0-l">
              <div className="dib marker">
                <p className="f5 f4-m f3-l">We&apos;re a Digital Agency specialized in</p>

              </div>
            </div>
            <div className="h5-ns">
              <ReactRotatingText
                className="f3 f2-m f-subheadline-l lh-solid pt3"
                items={
                  [
                    'Information Architecture',
                    'Project Management',
                    'Software Engineering',
                    'Front-end Development',
                    'Business Analysis',
                    'Responsive Web Design',
                    'User Interfaces',
                    'User Experience',
                    'Search Engine Optimization',
                    'Accessibility',
                    'Usability',
                  ]
                }
              />
            </div>
          </div>
        </div>
        <div className="white absolute-ns bottom-0 right-0 pa4 w-100 mw6-ns bg-black-70">
          <h2 className="f6 f5-ns ttu mb0">Latest Work</h2>
          <p className="f3 f2-ns b lh-solid mb2 mb3-ns">{title}</p>
          <p className="f6 f5-ns mb3">{excerpt}</p>
          <Link to={`/works/${slug}`} className="f6 f5-ns ba br-pill pv2 ph3 dib no-underline white bw1 bg-animate hover-bg-white hover-black">Read More</Link>
        </div>
      </BackgroundImage>
      <section id="why" className="bg-white pv4 pv5-ns">
        <div className="w-100 mw8-ns center ph3 ph5-ns tc">
          <h2 className="f2 fw4 mb4 lh-title">
            <div className="dib">
              Why
              {' '}
              <div className="w4 dib mr2 relative">
                <Logo />
                <div className="top-1 w4 absolute">
                  <Stroke color="#4581c2" />
                </div>
              </div>?
            </div>
          </h2>
          <p className="mb3 mb4-ns f5 f4-ns">
            Our motto is <span className="b">&ldquo;Excellence by Innovation, Speed and Quality&rdquo;</span>. These three
            pillars drive our work.
          </p>
          <p className="mb3 mb4-ns f5 f4-ns">
            We <span className="b">innovate</span> by seeking the latest technologies and solutions that cater to our
            clients&apos; needs, and because time is money, we deliver our solutions in a
            <span className="b"> timely and cost-effective</span> manner,<br />
            always putting <span className="b">quality first</span>.
          </p>
          <p className="f5 f4-ns">
            More than happy customers, we strive for <span className="b">your customers to be even happier</span>.
          </p>
        </div>
      </section>
      <section id="services" className="bg-blue white pv4 pv5-ns">
        <div className="w-100 mw8-ns center ph3 ph2-m ph3-l ph5-ns">
          <h2 className="f3 f2-ns fw4 mb2 mb0-ns lh-title tc">Our Services</h2>
          <p className="f5 f4-ns mb3 mb4-ns tc lh-title">See below how we can help your company:</p>
          <div className="flex flex-wrap">
            {services.map((service) => {
              const { content, icon, name } = service;
              return (
                <div key={name} className="w-100 w-50-ns pa2 pa3-l">
                  <div className="bg-white-10 br3 pa3 flex">
                    <div className="w-20 w-10-m w-20-l flex justify-center items-center">
                      <i className={`f2 f1-l ${icon}`} />
                    </div>
                    <div className="w-80 w-90-m w-80-l pl2 pl3-m pl2-l">
                      <h3 className="f4 f3-l b fw4 lh-title mb1">{name}</h3>
                      <p className="lh-copy white-70 f6 f5-l">{content}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div id="projects" className="pt4 w-100 mw8-ns center">
        <h2 className="pt3-ns pl3 pl4-l mb3 mb0-l f4 f3-ns nt2 mt0-ns">Check some of our projects:</h2>
        <Masonry className="showcase">
          {data.IndexQuery.edges.slice(1).map(({ node: work }) => (
            <div key={work.id} className="showcase__item">
              <figure className="card">
                <Link to={`/works/${work.slug}`} className="card__image">
                  <Img fluid={work.coverImage.fluid} />
                </Link>
                <figcaption className="card__caption">
                  <h6 className="card__title">
                    <Link to={`/works/${work.slug}`}>{work.title}</Link>
                  </h6>
                  <div className="card__description">
                    <p>
                      {work.excerpt}
                      {' '}
                      <Link to={`/works/${work.slug}`} className="bb b--dashed no-underline gray b--mid-gray">Read details.</Link>
                    </p>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </Masonry>
      </div>
      <div className="ph3-m ph4-l mw8-ns center w-100">
        <BackgroundImage
          Tag="section"
          id="contact"
          className="mt4-ns w-100 mw8-ns center tc mb5 br4-ns pv6 overflow-hidden white"
          fluid={contactImageBg}
        >
          <h2 className="f3">Contact Us</h2>
          <a href="mailto:contact@bluetouch.digital" className="no-underline white">contact@bluetouch.digital</a>
        </BackgroundImage>
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: shape({}).isRequired,
};

export default IndexPage;

export const query = graphql`
  query {
    IndexQuery: allDatoCmsWork(sort: { fields: [meta___updatedAt], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          coverImage {
            fluid(maxWidth: 1280, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
    contactImage: file(relativePath: { eq: "contact-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
