import React from 'react';
import { string } from 'prop-types';

const Logo = ({ color }) => {
  const colorFill = color || '#010101';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297.91 9.57">
      <path
        d="M0,8.54C43.68-2.39,267.9-2.95,297.91,7.59c-.53.27-.92.66-1.3.65C198.67,4.8,100.73,2.85,2.84,9.57,2.29,9.61,1.71,9.18,0,8.54Z"
        style={{ fill: colorFill }}
      />
    </svg>
  );
};

Logo.propTypes = {
  color: string.isRequired,
};

export default Logo;
